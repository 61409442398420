<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div

      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >

      <span
        class="font-weight-bolder text-primary"
      >HỆ THỐNG QUẢN LÝ THU HỌC PHÍ TINGEE
      </span>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->

      <!-- <ProcessDropDown /> -->
      <dark-Toggler class="d-none d-lg-block" />

      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { getUserData, parseDateToString, updateUserAbilities } from '@/auth/utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { $themeConfig } from '@themeConfig';
import useJwt from '@/auth/jwt/useJwt';
import NotificationDropdown from './NotificationDropdown.vue';
// import Locale from './Locale.vue';
import UserDropdown from './UserDropdown.vue';
// import ProcessDropDown from './ProcessDropDown.vue';

export default {
  components: {
    BLink,
    BNavbarNav,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    // Locale,
    UserDropdown,
    // ProcessDropDown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  sockets: {
    importProcessUpdate(data) {
      if (data && data.status === 'success') {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Import thành công',
            text: 'Hãy kiểm tra dữ liệu để chắc chắn rằng tất cả thông tin đã được import nhé',
            icon: 'UploadIcon',
            variant: 'success',
          },
        });
      }
      // } else if (data && data.status === 'fail') {
      //   this.$toast({
      //     component: ToastificationContentVue,
      //     props: {
      //       title: 'Import thất bại',
      //       text: 'Hãy kiểm tra lại dữ liệu nhé',
      //       icon: 'UploadIcon',
      //       variant: 'danger',
      //     },
      //   });
      // }
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      parseDateToString,
    };
  },
  mounted() {
    // this.$socket.connect();
    // this.$socket.emit('join', {});
    this.refetchPermissions();
  },
  methods: {
    refetchPermissions() {
      const userData = getUserData();
      if (userData) {
        useJwt.getPermissions().then(permissionResponse => {
          updateUserAbilities(permissionResponse.data);
          this.$ability.update(permissionResponse.data);
        }).catch(() => {

        });
      }
    },
  },
  setup() {
    const { appName } = $themeConfig.app;

    return {
      appName,
    };
  },
};
</script>

<style lang="scss">
.animate-character
{
  //  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>
